import React from "react"
import { Link } from "gatsby"
import Layout from "./../../components/layouts/Default"
import Seo from "./../../components/layouts/Seo"
import Header from "./../../components/common/Header"
import SvgFeature from "./../../components/common/SvgFeature"
import { graphql, useStaticQuery } from "gatsby"

import FeatureSection from "./../../components/containers/FeatureSection"
import ContContentTwoColumns from "./../../components/containers/ContContentTwoColumns"
import ContentTextbox from "./../../components/common/ContentTextbox"

import CtaSection from "./../../components/common/CtaSection"

import illustrationsShield from "./../../data/img/illustrations/shield.svg"
import illustrationsStatistics from "./../../data/img/illustrations/statistics.svg"

export default function Page() {
  const data = useStaticQuery(graphql`
    query VersicherungenBerufsunfaehigkeitsversicherung {
      image: file(
        relativePath: {
          eq: "headers/dierda_berufsunfaehigkeitsversicherung_header.jpg"
        }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const anfrageLink =
    "/versicherungen/berufsunfaehigkeitsversicherung/anfragen/"

  const svg = {
    shield: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="inline transition-colors duration-300"
        width="32"
        height="32"
        viewBox="0 0 24 24"
        strokeWidth="1"
        stroke="var(--primary)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z"></path>
        <path d="M12 3a12 12 0 0 0 8.5 3a12 12 0 0 1 -8.5 15a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3"></path>
      </svg>
    ),
    check: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="inline transition-colors duration-300"
        width="32"
        height="32"
        viewBox="0 0 24 24"
        strokeWidth="1"
        stroke="var(--primary)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <rect x="4" y="4" width="16" height="16" rx="2" />
        <path d="M9 12l2 2l4 -4" />
      </svg>
    ),
  }

  const content = {
    warum: (
      <ContentTextbox title="Warum brauchst Du eine Berufs&shy;unfähigkeits&shy;versicherung?">
        <p>
          <img
            src={illustrationsShield}
            alt="Mann stheht vor einem Schild"
            className="w-full xxs:w-40 xxs:float-right"
          />
          Laut Statistik kann jeder vierte Berufstätige aufgrund von Krankheiten
          oder Unfällen nicht mehr bis zum Renteneintrittsalter arbeiten.{" "}
          <strong>
            Sollte die Berufs&shy;unfähigkeit eintreten, ist meist die eigene
            Existenz bedroht, da die staatliche Erwerbsminderungsrente nur das
            Existenzminimum absichert. Durch die Arbeitsunfähigkeit kann kein
            weiteres Einkommen mehr erwirtschaftet werden und somit ist man
            abhängig vom Sozialstaat.
          </strong>{" "}
          Diese Risiken sind nicht auf bestimmte Berufe beschränkt, sondern
          können jeden Erwerbstätigen treffen. Umso sinnvoller ist eine
          Absicherung bereits in jungen Jahren, weil noch keine Vorerkrankungen
          vorliegen und die Versicherungspolice entsprechend günstiger ist.
        </p>
      </ContentTextbox>
    ),
    was: (
      <ContentTextbox title="Was ist eine Berufs&shy;unfähigkeits&shy;versicherung?">
        <p>
          <strong>
            Wenn Du in Deinem letzten Beruf für mindestens sechs Monate nicht
            mehr arbeiten kannst und deine Berufs&shy;unfähigkeit mindestens 50%
            beträgt, sichert eine Berufs&shy;unfähigkeits&shy;versicherung eine
            monatliche Rente zu.
          </strong>{" "}
          Diese erhältst Du selbstverständlich zusätzlich zur
          Erwerbsminderungsrente. So schützt Du Deine Existenz, falls Deine
          Arbeitskraft ausfällt.
        </p>
        <p>
          Die Berufs&shy;unfähigkeits&shy;versicherung bietet Dir im Vergleich
          zur staatlichen Erwerbsminderungsrente folgende Vorteile:
        </p>
        <ul>
          <li>kein Verweis auf eine andere Tätigkeit</li>
          <li>die Art der Erkrankung oder des Unfalls ist unerheblich</li>
          <li>weltweite Absicherung</li>
          <li>leistet schon ab dem sechsten Monat</li>
        </ul>
        <p>
          Die Rente der Berufs&shy;unfähigkeits&shy;versicherung erhältst Du bei
          Vorliegen der Voraussetzungen bis zum Rentenbeginn.
        </p>
      </ContentTextbox>
    ),
    ursachen: (
      <ContentTextbox title="Was sind die größten Ursachen für eine Berufs&shy;unfähigkeit?">
        <p>Die häufigsten Ursachen für eine Berufs&shy;unfähigkeit sind:</p>
        <ul>
          <li>31,32 Prozent: Psychische Erkrankungen</li>
          <li>
            20,01 Prozent: Erkrankungen des Skelett- und Bewegungsapparates
          </li>
          <li>15,93 Prozent: Krebs und andere bösartige Geschwüre</li>
          <li>14,62 Prozent: Sonstige Erkrankungen</li>
          <li>9,39 Prozent: Unfälle</li>
          <li>7,72 Prozent: Erkrankungen des Herzens und des Gefäßsystems</li>
        </ul>
        <img
          src={illustrationsStatistics}
          alt="Grafik über eine Statistik"
          className="w-64 mx-auto"
        />
      </ContentTextbox>
    ),
    staat: (
      <ContentTextbox title="Wie hoch ist die staatliche Rente bei Erwerbslosigkeit?">
        <p>
          Es besteht nur dann ein Anspruch auf die staatliche
          Erwerbsminderungsrente, wenn Du nicht mehr in der Lage bist, täglich
          länger als sechs Stunden zu arbeiten. Dein zuvor ausgeübter Beruf
          spielt dabei keine Rolle – Du kannst auf jede andere Tätigkeit
          verwiesen werden.{" "}
          <strong>
            Bei einem täglichen Leistungsvermögen von 3 – 6 Stunden zahlt die
            gesetzliche Rentenversicherung nur eine teilweise
            Erwerbsminderungsrente. Sie entspricht maximal 20 Prozent des
            letzten Bruttoeinkommens.
          </strong>
        </p>
        <p>
          <strong>
            Liegt das Leistungsvermögen täglich unter 3 Stunden, besteht
            Anspruch auf die volle Erwerbsminderungsrente von bis zu 40 Prozent
            des letzten Bruttoeinkommens.
          </strong>{" "}
          Selbst wenn Du nicht auf eine andere Tätigkeit verwiesen wirst und
          Deine staatliche Rente tatsächlich bewilligt wird, ist eine
          Absicherung Deines gewohnten Lebensstandards mit Hilfe der staatlichen
          Erwerbsminderungsrente nicht möglich.
        </p>
      </ContentTextbox>
    ),
    hoehe: (
      <ContentTextbox title="Wie hoch ist die Rente bei Berufs&shy;unfähigkeits&shy;versicherungen?">
        <p>
          Während sich die Höhe einer Erwerbsminderungsrente nach der Höhe der
          zuvor eingezahlten Sozialversicherungsbeiträge richtet, vereinbarst Du
          mit der Berufs&shy;unfähigkeits&shy;versicherung einen festen Betrag.
          Unsere unabhängige Beratung hilft Dir, die Höhe Deines Bedarfs zu
          ermitteln.{" "}
          <strong>
            Als grober Anhaltspunkt dienen 70 bis 80 Prozent des aktuellen
            Nettoeinkommens.
          </strong>{" "}
          Bitte berücksichtige in diesem Zusammenhang, dass Du unter Umständen
          die Beiträge für die gesetzliche Rentenversicherung bis zum Anspruch
          auf die Regelaltersrente weiter entrichten musst.
        </p>
      </ContentTextbox>
    ),
    kosten: (
      <ContentTextbox title="Was kostet die Berufs&shy;unfähigkeits&shy;versicherung?">
        <p>
          Die Art der ausgeübten Tätigkeit beeinflusst die Höhe des Beitrages.
          Da jeder Versicherer die Risiken unterschiedlich kalkuliert, empfiehlt
          sich ein Vergleich zwischen verschiedenen Anbietern. Die Höhe der
          Prämie richtet sich darüber hinaus nach:
        </p>
        <ul>
          <li>dem ausgeübten Beruf</li>
          <li>dem Eintrittsalter des Versicherten</li>
          <li>der Versicherungslaufzeit</li>
          <li>vorliegenden Vorerkrankungen</li>
          <li>der Ausübung von Risikosportarten</li>
        </ul>
      </ContentTextbox>
    ),
    beachten: (
      <ContentTextbox title="Was solltest Du bei einer Berufs&shy;unfähigkeits&shy;versicherung beachten?">
        <p>
          Grundsätzlich gilt: Wir beraten Dich seriös und fair. Damit Deine
          Versicherung am Ende auf jeden Fall auch greift gilt es eine
          Versicherung zu finden, die nach Deinen individuellen Bedürfnissen
          angepasst ist.{" "}
          <strong>
            Wir bieten Dir flexiblen Schutz ohne Fallen, sodass die folgenden
            Punkte bei Dir nicht zutreffen werden.
          </strong>
        </p>
        <p>
          Folgende Fallstricke können bei einer
          Berufs&shy;unfähigkeits&shy;versicherung auftauchen:
        </p>
        <p>
          Falls versehentlich oder vorsätzlich Gesundheitsfragen im Zusammenhang
          mit dem Abschluss der Versicherung falsch oder unvollständig
          beantwortet werden, kann der Versicherer im Leistungsfall vom Vertrag
          zurücktreten. Zur Vermeidung von Missverständnissen ist es
          empfehlenswert, nicht nur die Einwilligung zur Anforderung Deiner
          Krankenakte zu erteilen, sondern auch selbst einen Blick in diese
          Unterlagen zu werfen. Der Fragebogen sollte sehr sorgfältig ausgefüllt
          werden und es sollte unbedingt eine Kopie für Deine Unterlagen
          aufbewahrt werden.
        </p>
        <p>
          Die Laufzeit sollte unbedingt bis zum Renteneintrittsalter gewählt
          werden. Die Versicherungsdauer und der Leistungszeitraum sollten
          gleich lang sein, da sonst die Gefahr von Zahlungslücken besteht.
          Sinnvoll ist eine Laufzeit bis zur Vollendung des 67. Lebensjahres, da
          die Rentenversicherung einen Rentenbeginn ab dem 63. Lebensjahr nur
          mit Abschlägen ermöglicht.
        </p>
        <p>
          Durch eine unabhängige Beratung ermitteln wir eine individuelle Lösung
          zur Absicherung Deiner Arbeitskraft. Hierdurch bekommst Du die
          Möglichkeit deine Berufs&shy;unfähigkeits&shy;versicherung mit einem
          Krankentagegeld, einer Dread Disease Versicherung oder einer
          lebenslangen Pflegerente zu kombinieren. Darüber hinaus besteht auch
          die Möglichkeit diese mit einer privaten Altersvorsorge oder einer
          Risikolebensversicherung zu kombinieren, allerdings sind diese
          Varianten meist intransparent und mit vielen versteckten Kosten
          verbunden sind.{" "}
        </p>
        <p>
          Eine Berufs&shy;unfähigkeits&shy;versicherung mit einer
          Nachversicherungsgarantie ermöglicht Dir die flexible Anpassung des
          Produktes an geänderte Lebensumstände. In diesem Fall kannst Du die
          Versicherungsleistung erhöhen, ohne dass eine erneute
          Gesundheitsprüfung erforderlich wird. Hierbei kann es sich um folgende
          Ereignisse handeln:
        </p>
        <ul>
          <li>Gründung einer Familie</li>
          <li>Erwerb einer Immobilie</li>
          <li>Gehaltserhöhung</li>
        </ul>
        <p>
          Empfehlenswert ist es auch, zu Beginn ein Produkt mit dynamischen
          Beiträgen zu wählen. Diese steigen jedes Jahr ohne erneute
          Gesundheitsprüfung automatisch um einen bestimmten Prozentsatz, um die
          Teuerung auszugleichen. Falls Dir die Prämie zu teuer wird, bekommst
          Du jedes Jahr die Möglichkeit der Erhöhung zu widersprechen. Sollte
          der Widerspruch allerdings in drei aufeinanderfolgenden Jahren
          erfolgen, entfällt die Beitragsdynamik was zur Folge hat, dass sowohl
          der Beitrag und auch die abgesicherte Rente bis zum Laufzeitende
          gleichbleibt. Also flexibel nach Deinen Lebensumständen.
        </p>
        <p>
          Berufs&shy;unfähigkeitsverträge beinhalten eine Beitragsverrechnung,
          wobei vorhandene Überschüsse in der Ansparphase direkt genutzt werden
          und es zu einer sofortigen Senkung des laufenden Beitrags kommt. Diese
          sind allerdings nicht garantiert und hängen von der Entwicklung des
          Anlagevermögens ab. Grundsätzlich gilt bei ausgewiesenen Brutto- und
          Nettobeiträgen: Zahlbeitrag = Nettobeitrag.
        </p>
      </ContentTextbox>
    ),
  }

  return (
    <Layout>
      <Seo
        title="Jetzt Berufsunfähigkeitsversicherung finden"
        description="Eine Erkrankung oder ein Unfall kann für Berufstätige schnell zu einem
          Leben am Rande des Existenzminimums führen. Eine Berufsunfähigkeitsversicherung den gewohnten Lebensstandard auch bei Wegfall der Arbeitsfähigkeit sicher."
        path="/versicherungen/berufsunfaehigkeitsversicherung/"
      />
      <Header
        link={anfrageLink}
        linktext="Jetzt Berufs&shy;unfähigkeits&shy;versicherung finden"
        image={data.image.childImageSharp.fluid}
      >
        <div className="text-sm font-semibold tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base">
          Versicherungen
        </div>
        <h1 className="mt-1 text-2xl leading-relaxed sm:text-3xl lg:text-4xl font-extrabold text-gray-900  ">
          Die{" "}
          <span className="text-blue-500">
            Dienst- und Berufs&shy;unfähigkeits&shy;versicherung
          </span>{" "}
          als individueller Schutz für jeden Erwerbstätigen
        </h1>

        <div className="my-8 inline-flex ">
          <Link to={anfrageLink}>
            <SvgFeature svg={svg.shield} title="Schütze Deine Existenz" />
          </Link>
        </div>

        <p className="text-lg leading-7 text-gray-700">
          Eine Erkrankung oder ein Unfall kann für Berufstätige schnell zu einem
          Leben am Rande des Existenzminimums führen. Während die staatlichen
          Sicherungssysteme nur noch Grundbedürfnisse abdecken, stellt eine
          Berufs&shy;unfähigkeits&shy;versicherung den gewohnten Lebensstandard
          auch bei Wegfall der Arbeitsfähigkeit sicher.
        </p>
      </Header>

      <FeatureSection title="Was uns besonders macht">
        <SvgFeature
          svg={svg.check}
          title="Unabhängiger Vergleich"
          text="Wir vergleichen bei allen Top-Versicherer und bieten Dir eine unabhängige Beratung."
        />
        <SvgFeature
          svg={svg.check}
          title="Monatliche Rente"
          text="Sichere Dich ab mit einer monatlichen Rente bei Berufsunfähigkeit."
        />
        <SvgFeature
          svg={svg.check}
          title="Deine Bedürfnisse"
          text="Wir bieten Dir selbstverständlich eine individuelle Beratung nach Deinen Bedürfnissen."
        />
        <SvgFeature
          svg={svg.check}
          title="Echte Absicherung"
          text="Wir finden für Dich nicht nur irgendeine Berufsunfähigkeitsversicherung. Wir bieten Dir flexiblen Schutz ohne Fallen."
        />
        <SvgFeature
          svg={svg.check}
          title="Kostenfreie Beratung"
          text="Wir beraten Dich natürlich kostenlos. Du zahlst nur für Deine Versicherung nach Abschluss der Police."
        />
      </FeatureSection>

      <ContContentTwoColumns col1={content.warum} col2={content.was} />

      <CtaSection
        title1="Schütze Deine Existenz"
        text="Die Dienst- und Berufs­unfähigkeits­versicherung als individueller Schutz für jeden Erwerbstätigen"
        link1={anfrageLink}
        link1text="Jetzt Berufs&shy;unfähigkeits&shy;versicherung finden"
      />

      <ContContentTwoColumns col1={content.ursachen} col2={content.staat} />
      <ContContentTwoColumns col1={content.hoehe} col2={content.kosten} />
      <ContContentTwoColumns col1={content.beachten} />
    </Layout>
  )
}
